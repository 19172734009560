import AudioPlayer from "react-h5-audio-player";
import "./App.css";
import "react-h5-audio-player/src/styles.scss";

function App() {
  console.log(window.innerHeight);
  return (
    <div className="App">
      <div
        className={`container mx-auto bg-[#284193] flex flex-col`}
        style={{ height: window.innerHeight + "px" }}
      >
        <div className="flex-grow tketag-bg mx-8"></div>
        <AudioPlayer
          autoPlay
          src="/tketag.mp3"
          onPlay={(e) => console.log("onPlay")}
          // other props here
        />
      </div>
    </div>
  );
}

export default App;
